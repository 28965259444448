/**
 * ExpringLink response example
 *                 {
 *                     "client": "pittnc.scriborder.com",
 *                     "id": "62353932663333622d633630342d343561662d396333632d383436613738343061626264",
 *                     "sentTo": "registrar@uchicago.edu",
 *                     "created": 1672758266815,
 *                     "expires": 1680580799000,
 *                     "maxViews": 10,
 *                     "viewCount": 1,
 *                     "comments": "",
 *                     "attachmentCategory": "sealable",
 *                     "attachmentOrderId": "DBLP4XW6G",
 *                     "attachmentFileId": 1672758236035,
 *                     "attachmentFileName": "NC Transcript",
 *                     "previouslyExported": true,
 *                     "metaData": {
 *                         "applicantemail": "david.yoon811@gmail.com",
 *                         "doctype": "Transcript",
 *                         "firstname": "David",
 *                         "districtname": "Pitt County Schools",
 *                         "dob": "08/11/2005",
 *                         "last_school_of_attendance": "DH CONLEY HIGH SCHOOL",
 *                         "middlename": "Taeha",
 *                         "suffix": "",
 *                         "lastname": "Yoon"
 *                     },
 *                     "lastViewDate": 1672758959107,
 *                     "userAccountId": "77ea7c6bb72946833816e131ba9f3f5b083c7e738ef81e0a0db97d91909fcd44",
 *                     "hash": "327ab086d935a1ac02a531cf0ada6aae5bdeb07e2b31c0faaee4f451260d2714",
 *                     "signedUrl": "pittnc.scriborder.com/62353932663333622d633630342d343561662d396333632d383436613738343061626264-1672758339406-signed.pdf"
 *                 }
 */

export type DocumentStatus = 'RECEIVED' | 'REVIEWED' | 'COMPLETE' | 'EXPIRED';
export type CRM_Status = 'SENT' | 'DO_NOT_SEND' | 'READY' | 'FAILED' | 'N_A';

export const CRM_STATUS_OPTIONS = ['SENT', 'DO_NOT_SEND', 'READY', 'FAILED', 'N_A'];

export const CRMStatusDisplayValues: Record<CRM_Status, string> = {
  SENT: 'Sent',
  DO_NOT_SEND: 'Do Not Send',
  READY: 'Ready',
  FAILED: 'Failed',
  N_A: 'N/A',
};

export const CRM_DISPLAY_OPTIONS = [
  CRMStatusDisplayValues['SENT'],
  CRMStatusDisplayValues['DO_NOT_SEND'],
  CRMStatusDisplayValues['READY'],
  CRMStatusDisplayValues['FAILED'],
  CRMStatusDisplayValues['N_A'],
];

export const CRMStatusReverseMap: Record<string, CRM_Status> = {
  Sent: 'SENT',
  'Do Not Send': 'DO_NOT_SEND',
  Ready: 'READY',
  Failed: 'FAILED',
  'N/A': 'N_A',
};

export interface DocumentMetadata {
  applicantemail: string;
  doctype: string;
  firstname: string;
  districtname: string;
  dob: string;
  dobvalue: string;
  last_school_of_attendance: string;
  middlename: string;
  suffix: string;
  lastname: string;
  studentid: string;
  crmStatus?: CRM_Status;
}

/**
 *  Document interface represents a stored documents meta data
 */
export interface Document {
  client: string;
  id: string;
  sentTo: string;
  created: number;
  expires: number;
  lastViewDate: number;
  comments: string;
  maxViews: number;
  viewCount: number;
  metaData: DocumentMetadata;
  hash: string;
  signedUrl: string;
  status: DocumentStatus;
  downloaded: number;
  attachmentFileId: string;
  attachmentFileName: string;
  selected: boolean;
  position: number;
  contentType: string;
  attachmentOrderId: string;
  applicationId: number;
  dataSent: number;
}

export interface QueuedDownload {
  id: string;
  accountId: string;
  userId: string;
  status: string;
  created: number;
  downloaded: number;
  expires: number;
  bucket: string;
  path: string;
  fileName: string;
  fileType: string;
  size: number;
}

export const DocumentTypeOptions = [
  'Final Transcript',
  'Current Courses',
  'Final Report Explanation of Change',
  'Grade Report/Report Card',
  'Immunization Records',
  'Initial Transcript',
  'Letter of Recommendation',
  'Mid-Year Transcript',
  'Mid-year Report Explanation of Change',
  'NACAC Mid-Year Report',
  'NACAC Fee Waiver',
  'NACAC School report',
  'Optional Report Explanation of Change',
  'Optional Transcript',
  'Other Final Report',
  'Other Mid-Year Report',
  'Other Optional Report',
  'Other School Report',
  'School Profile',
  'Transfer Transcript',
  'Written Evaluation',
];
